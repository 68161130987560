import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import NewsPostPreviewContainer from "../components/news/news-post-preview-container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import NewsListPagination from "../components/news/news-list-pagination";
import { format } from "date-fns";

export const query = graphql`
  query NewsListTemplateQuery($skip: Int!, $limit: Int!) {
    news: allSanityNews(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const NewsLsitPage = ({ data, errors, pageContext }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = (data || {}).news
    ? mapEdgesToNodes(data.news)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <SEO
        title="Wcześniejsze wiadomości"
        description="Lista wiadomości wcześniejszych, niż aktualności na stronie głównej"
      />
      <h2>Wcześniejsze wiadomości</h2>
      <span style={{ display: "block", textAlign: "center" }}>
        (od {format(postNodes[postNodes.length - 1].publishedAt, "DD.MM.YYYY")} r. do{" "}
        {format(postNodes[0].publishedAt, "DD.MM.YYYY")} r.)
      </span>
      {postNodes && <NewsPostPreviewContainer postNodes={postNodes} />}
      <NewsListPagination {...pageContext} />
    </Layout>
  );
};

export default NewsLsitPage;
